// src/ConfigureCoverage.js

import React, { useState, useRef, useEffect, forwardRef } from 'react'
import { View, StyleSheet, Pressable, Button, ActivityIndicator, useWindowDimensions, Dimensions } from 'react-native'
import Pane from './Pane';
import Row from './Row';
import * as Constants from '../theme'
import ExpandingTextField from './ExpandingTextField';
import TextInputField, { SelectField, RadioField, SwitchButton, BooleanSwitch, CheckField } from './Input';
import { Select, CheckIcon, ChevronDownIcon, Radio, Box, Icon } from "native-base";
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import * as Data from './libs/data'
import * as DataTypes from './libs/DataTypes'
import Text from './Text';

const ConfigureCoverage = (props) => {

    let [fvCount, setFVCount] = useState(1);
    let [fvFocusIndex, setFVFocusIndex] = useState(0);
    let [mbCount, setMBCount] = useState(1);
    let [mbFocusIndex, setMBFocusIndex] = useState(0);

    let budgetFvReloadCount = useRef(0);

    let productClasses = useRef([]);

    useEffect(() => {
        props.setProductClasses(productClasses.current);
    }, [productClasses])

    /*useEffect(() => {
        if (props.modeFV) {
            optimizeFVs();
            if (fvFocusIndex != 0) {
                props.fvRefs['current'][fvFocusIndex].focus();
            }

        } else {
            optimizeMBs();
            if (mbFocusIndex != 0) {
                props.mbRefs['current'][mbFocusIndex].focus();
            }

        }

    }, [fvFocusIndex, mbFocusIndex])*/



    const quoteOptimizer = (getter, setter) => {
        let orig = [...getter];
        let x = [];
        orig.forEach(function (i) {
            let item = i.replace(/(^[\/\\\|\-]|[\/\\\|\-]$)/g, '');
            x = x.concat(item.split(/[\/\\\|\-]/));
        });
        for (let id = 0; id < x.length; id++) {
            x[id] = x[id].replace(/\.\d+\s*$/, '');
            x[id] = x[id].replace(/[^0-9kmKM\.]/g, '');
            if (x[id].includes('k')) {
                let no_k = parseFloat(x[id].replace(/[^0-9\.]/g, ''));
                x[id] = (no_k * 1000) + "";
            };
            if (x[id].includes('m')) {
                let no_k = parseFloat(x[id].replace(/[^0-9\.]/g, ''));
                x[id] = (no_k * 1000000) + "";
            };

            try {
                if (parseFloat(x[id]) > 0) {
                    let z = parseFloat(x[id]).toLocaleString("en-US");
                    x[id] = '$' + z;
                }
            } catch (e) { }
        }
        setter(x);
        return x;
    };

    const optimizeMBs = () => {
        return quoteOptimizer(props.monthlyBudgets, props.setMonthlyBudgets)
    };

    const optimizeFVs = () => {
        return quoteOptimizer(props.faceValues, props.setFaceValues)
    };


    let [eraseBothFields, setEraseBothFields] = useState(false);
    let [activeAutocomplete, setActiveAutocomplete] = useState('');


    useEffect(() => {
        budgetFvReloadCount.current += props.resetCount;
    }, [props.resetCount]);

    useEffect(() => {
        if (!props.bppCase) return;
        if (props.bppCase == 'reset') return;

        props.setShowFEX(true);
        props.setShowMedsup(true);
        props.setShowTerm(true);
        props.setShowPreneed(true);

        if (props.bppCase.hasOwnProperty('state')) {
            if (props.bppCase.state.length > 1) {
                props.setState(props.bppCase.state);
                setStateForCoverage(props.bppCase.state);
            } else {
                props.setState(' ');
            }
        } else {
            props.setState(' ');
        }


        if ((props.bppCase.zipCode !== undefined && props.bppCase.zipCode !== null)) {
            if (typeof props.bppCase.zipCode === 'object') {
                if (Object.keys(props.bppCase.zipCode).length != []) {
                    props.setZip(props.bppCase.zipCode);
                } else {
                    props.setZip(' ');
                }
            } else {
                props.setZip(props.bppCase.zipCode);
            }

        } else {
            props.setZip(' ');
        }
        if (props.bppCase.setOpenEnrollment !== undefined && props.bppCase.setOpenEnrollment !== null) {
            props.setIsOpenEnrollment(props.bppCase.setOpenEnrollment == 'true' ? 'Yes' : 'No');
        } else {
            props.setIsOpenEnrollment(' ');
        }
        let fvs = [];
        let mbs = [];
        let focusFV = true;
        if (props.bppCase.face_value != '') {
            try {
                fvs = props.bppCase.face_value.split('|').filter(data => data.replace(/(\$0|^0.*)/g, ''));
            } catch (e) { }
            if (fvs.length > 0) {
                props.setFaceValues(fvs);
                focusFV = true;
            }
        } else {
            props.setFaceValues(['']);
            focusFV = true;
        }

        if (props.bppCase.hasOwnProperty('monthly_budget')) {
            if (props.bppCase.monthly_budget != '') {
                try {
                    mbs = props.bppCase.monthly_budget.split('|').filter(data => data.replace(/(\$0|^0.*)/g, ''));
                } catch (e) { console.log(e); }
                if (mbs.length > 0) {
                    props.setMonthlyBudgets(mbs);
                    focusFV = false;
                    props.setModeFV(false);
                }
            } else {
                props.setMonthlyBudgets(['']);
                focusFV = true;
            }
        } else {
            props.setMonthlyBudgets(['']);
        }

        // this will be auto-changed based on live data
        props.setShowHealthProfile(false);

        if (Array.isArray(props.bppCase.Conditions.Condition)) {
            let newConds = [];
            props.bppCase.Conditions.Condition.forEach(function (cond) {
                let x = new DataTypes.Condition();
                x.name(cond.Name);
                x.last_treatment(cond.LastTreatment);
                x.was_diagnosed(cond.WasDiagnosed);
                x.index = props.itemIndex.current++;
                newConds = newConds.concat(x);

            });
            props.setConditions(newConds);

            if (newConds.length > 0) {
                props.setShowHealthProfile(true);
            }
        } else {

            if (Array.isArray(props.bppCase.Conditions)) {
                let newConds = [];
                props.bppCase.Conditions.forEach(function (c) {
                    let cond = c.Condition;
                    let x = new DataTypes.Condition();
                    x.name(cond.Name);
                    x.last_treatment(cond.LastTreatment);
                    x.was_diagnosed(cond.WasDiagnosed);
                    newConds = newConds.concat(x);

                });
                props.setConditions(newConds);
                if (newConds.length > 0) {
                    props.setShowHealthProfile(true);
                }
            } else {

                if (typeof props.bppCase.Conditions.Condition == 'object') {
                    let newConds = [];
                    let cond = props.bppCase.Conditions.Condition
                    let x = new DataTypes.Condition();
                    x.name(cond.Name);
                    x.last_treatment(cond.LastTreatment);
                    x.was_diagnosed(cond.WasDiagnosed);
                    if (cond.Index == '') cond.Index = 0;
                    x.index = props.itemIndex.current++;
                    newConds[cond.Index] = x;

                    props.setConditions(newConds);

                    if (newConds.length > 0) {
                        props.setShowHealthProfile(true);
                    }
                } else {
                    props.setConditions([]);
                }
            }
        }

        if (Array.isArray(props.bppCase.Medications.Medication)) {

            let newMeds = [];
            props.bppCase.Medications.Medication.forEach(function (med) {
                let x = new DataTypes.Medication();
                x.name(med.Name);
                x.use(med.Use);
                x.last_fill(med.Use);
                x.dosage(med.Dosage);
                x.last_fill(med.LastFill);
                x.first_fill(med.FirstFill);
                if (med.Index == '') med.Index = 0;
                x.index = props.itemIndex.current++;
                newMeds[med.Index] = x;

            });
            props.setMedications(newMeds);

            if (newMeds.length > 0) {
                props.setShowHealthProfile(true);
            }

        } else {
            if (Array.isArray(props.bppCase.Medications)) {
                // autosaved meds are in this format
                let newMeds = [];
                props.bppCase.Medications.forEach(function (m) {
                    let med = m.Medication;
                    let x = new DataTypes.Medication();
                    x.name(med.Name);
                    x.use(med.Use);
                    x.last_fill(med.Use);
                    x.dosage(med.Dosage);
                    x.last_fill(med.LastFill);
                    x.first_fill(med.FirstFill);
                    x.index = props.itemIndex.current++;
                    if (med.Index == '') med.Index = 0;
                    newMeds[med.Index] = x;

                });
                props.setMedications(newMeds);
                if (newMeds.length > 0) {
                    props.setShowHealthProfile(true);
                }
            } else {

                if (typeof props.bppCase.Medications.Medication == 'object') {
                    let newMeds = [];
                    let med = props.bppCase.Medications.Medication;
                    let x = new DataTypes.Medication();
                    x.name(med.Name);
                    x.use(med.Use);
                    x.dosage(med.Dosage);
                    x.last_fill(med.LastFill);
                    x.first_fill(med.FirstFill);
                    x.index = props.itemIndex.current++;
                    if (med.Index) {
                        newMeds[med.Index] = x;
                    } else {
                        newMeds = newMeds.concat(x);
                    }


                    props.setMedications(newMeds);
                    if (newMeds.length > 0) {
                        props.setShowHealthProfile(true);
                    }

                } else {
                    props.setMedications([]);
                }
            }
        }


        if (props.bppCase.restrict_rank) {
            props.setRestrictResults(props.bppCase.restrict_rank);
        } else {
            props.setRestrictResults(Constants.RestrictOptions[0].value);
        }


        props.setConfigureCoverageLoadedRef(true);
        budgetFvReloadCount.current++;


    }, [props.bppCase])



    const ProductClassSelect = (props) => {

        let selectedProductTypes = [];
        let selectedProductTypesOrig = [];
        let selectedProducts = Data.filterProductsToOnlyChecked(props.selectedProducts);

        if (Object.keys(selectedProducts).length > 0) {
            selectedProductTypes = Object.keys(selectedProducts).map((x) => { return { 'label': Data.getPrettyProductType(x), 'value': x } });
        }
        let classes = []
        let foundMedsup = false;
        selectedProductTypesOrig = selectedProductTypes.map((obj) => {
            if (obj.label.includes('Final Expense')) obj.label = 'FEX';
            if (obj.label.includes('Medicare Supplement')) { obj.label = 'MedSup'; foundMedsup = true; }
            if (obj.label.includes('Beta')) obj.label = obj.label.replace(/\(*Beta\)*/i, '');

            obj.label = obj.label.trim();

            classes.push(obj.value);

            return obj;
        })
        productClasses.current = classes;

        return (<>
            {Object.keys(selectedProductTypes).length > 1 ?
                <Row key={`product-classes-${productClasses.current.length}-${z.current}`} label="Product Types" rightStyle={[Constants.styles.flexRow, { flexWrap: 'wrap', justifyContent: 'flex-start', maxWidth: 430 }]}>
                    {
                        // @TODO get the info back to the App
                        selectedProductTypes.map((product) => {
                            return <CheckField key={`cb-product-class-${product.value}-${z}`} style={{ marginRight: 10 }} labelStyle={{ width: 75 }}
                                onChange={() => {
                                    z.current++;
                                    if (product.value == 'medsup') props.setShowMedsup(!props.showMedsup);
                                    if (product.value == 'fex') props.setShowFEX(!props.showFEX);
                                    if (product.value == 'term') props.setShowTerm(!props.showTerm);
                                    if (product.value == 'preneed') props.setShowPreneed(!props.showPreneed);

                                    if (productClasses.current.includes(product.value)) {
                                        productClasses.current = productClasses.current.filter(e => e != product.value);
                                    } else {
                                        productClasses.current = productClasses.current.concat(product.value)
                                    }

                                }}
                                label={product.label} value={
                                    product.value == 'medsup' ? props.showMedsup :
                                        (product.value == 'fex' ? props.showFEX :
                                            (product.value == 'term' ? props.showTerm
                                                : props.showPreneed))} ></CheckField>
                        })
                    }
                </Row>
                : null
            }
        </>)
    };


    let z = useRef(1);

    let [stateForCoverage, setStateForCoverage] = useState(props.defaultState);
    useEffect(() => {
        setStateForCoverage(props.state)
    }, [props.state])

    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    let healthUnderwritingLabelStyle = isMobile ? { width: 200, maxWidth: 200 } : {};
    return (
        <Pane heading='Quoting Requirements'>
            <ProductClassSelect {...props} />

            <Row label="State" containsAutocomplete={1}>
                <View>
                    <TextInputField key={`state-${budgetFvReloadCount}`} prefixAdornment='search'
                        value={stateForCoverage} onlySyncOnBlur={1} getter={stateForCoverage} setter={setStateForCoverage} style={Constants.styles.textControl}
                        onBlur={(e) => {
                            try {
                                e.target.value = e.target.value.trim();
                            } catch (e) { }

                            if (e.target.value.length == 2) {
                                e.target.value = Data.zysys_get_state_from_abbreviation(e.target.value);
                                setStateForCoverage(Data.zysys_get_state_from_abbreviation(e.target.value));
                                props.setState(Data.zysys_get_state_from_abbreviation(e.target.value));
                            } else {
                                setStateForCoverage(e.target.value);
                                props.setState(e.target.value);
                            }
                        }}
                        activeAutocomplete={activeAutocomplete} setActiveAutocomplete={setActiveAutocomplete}
                        autocomplete={Constants.StateList.map(function (x) { return x.value; })}
                        onAutocompleteSelect={(val) => {
                            setStateForCoverage(val);
                            props.setState(val);
                        }}
                        readonly={props.isReadonly.current}

                    ></TextInputField>
                </View>
            </Row>
            {Object.keys(Data.filterProductsToOnlyChecked(props.selectedProducts)).includes('medsup') && props.showMedsup ? <>
                <Row label="Zip Code" key={`zip-${z}`}>
                    <View>
                        <TextInputField key={`zip-${budgetFvReloadCount}`} readonly={props.isReadonly.current}
                            syncWithGetter={1} value={props.zip} getter={props.zip} setter={props.setZip}
                            autoComplete="off" style={Constants.styles.textControlSM} id="zipCode" type='number' ></TextInputField>
                    </View>
                </Row>
                <Row label='Open Enrollment' key={`open-enrollment-${z}`}>
                    <RadioField name="openEnrollment" key={`open-enrollment-${budgetFvReloadCount}`} readonly={props.isReadonly.current}
                        getter={props.isOpenEnrollment} setter={props.setIsOpenEnrollment}
                        id="openEnrollment" options={[{ label: 'Yes', value: 'Yes', id: 'openEnrollmentYes' }, { label: 'No', value: 'No', id: 'openEnrollmentNo' }]}></RadioField>
                </Row>
            </>
                : null}
            <Row label="" style={{ marginTop: Constants.rowMarginBottom * 0.2 }} bottomSpacing={Constants.rowMarginBottom * .8}>
                <SwitchButton
                    leftButtonLabel="Face Value"
                    rightButtonLabel="Max Monthly Budget"
                    styles={{
                        activeMode: styles.activeMode,
                        inactiveMode: styles.inactiveMode,
                        activeModeText: styles.activeModeText,
                        inactiveModeText: styles.inactiveModeText,
                        leftButton: styles.leftButton,
                        rightButton: styles.rightButton,
                        selectorButton: styles.selectorButton,
                        selectorButtonsWrap: styles.selectorButtonsWrap,
                    }}
                    setMode={props.setModeFV}
                    getMode={props.modeFV}
                    readonly={props.isReadonly.current}


                />
            </Row>
            {
                props.modeFV ? <ExpandingTextField key={`fvs-${budgetFvReloadCount.current}`}
                    getter={props.faceValues}
                    setter={props.setFaceValues}
                    focusGetter={fvFocusIndex}
                    focusSetter={setFVFocusIndex}
                    refs={props.fvRefs}
                    fixer={quoteOptimizer}
                    idPrefix="face-value"
                    fieldTitle="Face Value(s)"
                    labelIconTooltip="Quick tip: Save time by writing something like 10k or 1.2m"
                    keyboardType='number-pad'
                    readonly={props.isReadonly.current}
                />
                    : null
            }
            {
                !props.modeFV ?
                    <ExpandingTextField key={`mbs-${budgetFvReloadCount.current}`}
                        getter={props.monthlyBudgets}
                        setter={props.setMonthlyBudgets}
                        focusGetter={mbFocusIndex}
                        focusSetter={setMBFocusIndex}
                        refs={props.mbRefs}
                        fixer={quoteOptimizer}
                        idPrefix="monthly-budget"
                        fieldTitle="Monthly Budget(s)"
                        keyboardType="number-pad"
                        readonly={props.isReadonly.current}
                    />

                    : null
            }
            <Row label="Quote Type">
                <View>
                    <SelectField key={`restrict-results-${budgetFvReloadCount}`}
                        getter={props.restrictResults}
                        setter={props.setRestrictResults}
                        selectStyle={styles.select}
                        defaultValue={Constants.RestrictOptions[0]}
                        readonly={props.isReadonly.current}
                        options={Constants.RestrictOptions}></SelectField>
                </View>
            </Row>

            <Row label="Quote with Underwriting Profile" leftPart={healthUnderwritingLabelStyle} labelStyle={healthUnderwritingLabelStyle} >
                {props.isRegistered && !props.readyForHealth ?
                    <ActivityIndicator style={{ alignItems: 'flex-start' }} animating={props.isRegistered && !props.readyForHealth} size="large" color={Constants.colors.primaryColorBG} />
                    :
                    <View style={{ marginTop: 9 }} >
                        <BooleanSwitch readonly={props.isReadonly.current} key={`show-health-profile-switch-${budgetFvReloadCount}`} get={props.showHealthProfile} set={props.setShowHealthProfile} setStates={[{ 'item': props.setOpenItemWizard, 'value': false }, { 'item': props.setClickedShowHealthProfile, 'value': !props.clickedShowHealthProfile }]}></BooleanSwitch>
                        <Text style={{ color: Constants.colors.captionColor, fontSize: Constants.fontSizes.caption, fontWeight: '500', marginTop: 9 }}>Enable this option for condition and health-based quoting.</Text>
                    </View>
                }
            </Row>
        </Pane >);
}

export default ConfigureCoverage


const showNicotineOptionsLinkTopSpacing = 14;
const styles = new StyleSheet.create({
    radio: {
        fontWeight: '500',
        fontSize: Constants.fontSizes.input,
    },
    select: {
        fontWeight: '500',
        fontSize: Constants.fontSizes.input,
        width: Constants.controlMaxWidth,
        borderRadius: 0,
    },
    selectorButtonsWrap: {
        flex: 1,
        flexDirection: 'row',
        borderCollapse: 'collapse',
    },
    selectorButton: {
        borderWidth: 1,
        borderColor: Constants.colors.primaryColorBG,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 12,
        paddingBottom: 12,
    },
    leftButton: {
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
        borderCollapse: true,

    },
    rightButton: {
        borderTopRightRadius: Constants.borderRadius,
        borderBottomRightRadius: Constants.borderRadius,
        borderCollapse: true,
    },
    activeMode: {
        backgroundColor: Constants.colors.primaryColorBG,
        color: Constants.colors.primaryColorText,
    },
    inactiveMode: {
        backgroundColor: 'transparent',
        color: Constants.colors.label,
    },
    activeModeText: {
        color: Constants.colors.primaryColorText,
    },
    inactiveModeText: {
        color: Constants.colors.label,
    }
});

/* need to add actual custom css:
TextInputField[type=radio]
    background-color: // blue
*/
